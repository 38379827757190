<template>
  <div class="about-author ">
    <div class="aboutAut-top clr">
      <a href="javascript:;" class="avater">
        <img :src="author.img" />
        <span v-if="author.celebrity" class="VVVV"></span>
        <!-- <div
          v-if="author.celebrity"
          style="    text-align: center;
    margin-top: 15px;
    color: red;
    font-size: 16px;"
        >
          知识网红
        
        </div> -->
      </a>
      <div class="msg">
        <div class="row">
          <span class="name">{{ author.name }}</span>
          <span class="type"> {{ author.industry }}</span>
        </div>
        <div class="nums">
          <span>点赞&nbsp;&nbsp;&nbsp;{{ author.praiseNum }}</span>
          <span class="line">/</span>
          <span>文章&nbsp;&nbsp;&nbsp;{{ author.articleNum }}</span>
        </div>
        <div class="saying">
          <span v-if="author.intro">[{{ author.intro }}]</span>
        </div>
        <div class="saying">
          <span>用户等级:{{ author.level }}</span>
        </div>
        <div class="attened">
          <a
            href="javascript:;"
            @click="concern()"
            v-show="isConcern2"
            v-if="isConcern"
            >+关注</a
          >
          <a
            href="javascript:;"
            @click="cancelConcern()"
            v-show="isConcern2"
            v-if="!isConcern"
            >取消关注</a
          >
        </div>
      </div>
    </div>
    <div class="sendMsg">
      <textarea
        placeholder="留言内容"
        style="resize: none;outline:none;"
        v-model="msg"
      ></textarea>
      <div class="sendBtn">
        <a href="javascript:;" @click="sendMessage(author.authorId)"
          >提交留言</a
        >
      </div>
    </div>
    <!-- <div class="personMsg">
      <span class="qIcon">
        <a href=" javascript:void(0)" target="_blank">
          <em></em>{{ author.xlName }}
        </a>
      </span>
      <span class="wxIcon"><em></em>{{ author.wxName }}</span>
      <span class="eIcon"
        ><em></em><a :href="author.email">{{ author.email }}</a></span
      >
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import {
  dataFormat,
  integralAddOne,
  showTimeFormat,
} from "@/js/common/index.js";
import { INSERT, QUERY, DELETE, UPDATE } from "@/services/dao.js";
export default {
  data() {
    return {
      msg: "",
      isConcern: false,
      isConcern2: "",
    };
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    authorId: {
      type: Number,
      required: true,
    },
  },

  created() {
    //获取登陆用户的ID
    this.user = this.$store.state.userinfo.id;
    this.init();
  },
  methods: {
    async init() {
      if (this.user == null || this.user == "" || this.user <= 0) {
        // 没有登录统一显示 +关注
        this.isConcern = true;
      } else {
        // 如用户是作者本人则不显示是否关注
        if (this.user == this.authorId) {
          // console.log(this.user,this.authorId)
          this.isConcern2 = false;
        } else {
          this.isConcern2 = true;
        }
        // 已登陆则判断是否关注
        let ocern = await QUERY(
          "post",
          "",
          "TTConcernMan(where: {concernMan: {_eq: " +
            this.user +
            "}, beConcernMan: {_eq: " +
            this.authorId +
            "}}) {    time  }"
        );
        // console.log("leave-message:75: ")
        // console.log(this.authorId)
        // console.log("5890"+ocern.data.TTConcernMan.length)
        if (ocern.data.TTConcernMan.length > 0) {
          this.isConcern = false;
        } else {
          this.isConcern = true;
        }
      }
    },
    async sendMessage(id) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: this.msg,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.msg = response.data.message.filterContent;
          console.log("赋值成功：" + this.centers);
          this.$emit("sendMessage", id, this.msg);
          this.msg = "";
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },

    async concern() {
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
        });
      } else {
        this.user = this.$store.state.userinfo.id;
        let obj = await INSERT(
          "POST",
          "",
          "insert_TtConcernMan(objects: {beConcernMan: " +
            this.author.authorId +
            ", concernMan: " +
            this.user +
            ', ip: "192.168.1.1", time: "' +
            new Date() +
            '"}) {    affected_rows  }'
        );
        if (obj.data.insert_TtConcernMan.affected_rows == 1) {
          this.isConcern = false;
          //获取用户的关注数
          let info = await QUERY(
            "post",
            "",
            "   PCUsers(where: {id: {_eq: " + this.user + "}}) { concernNum  }"
          );
          // 关注数+1
          info.data.PCUsers[0].concernNum += 1;
          //修改用户的关注数
          let datas = await UPDATE(
            "POST",
            "",
            "  update_User(where: {id: {_eq: " +
              this.user +
              "}}, _set: {concernNum: " +
              info.data.PCUsers[0].concernNum +
              "}) {  affected_rows }"
          );
          integralAddOne(this.user); ////关注积分加1
        } else {
          this.isConcern = true;
        }
        // console.log("leave-message: concern")
        // console.log(obj)
        //alert("后台支持");
      }
    },
    async cancelConcern() {
      this.user = this.$store.state.userinfo.id;
      let obj = await DELETE(
        "POST",
        "",
        " delete_TtConcernMan(where: {beConcernMan: {_eq: " +
          this.author.authorId +
          "}, concernMan: {_eq: " +
          this.user +
          "}}) {    affected_rows  }"
      );

      if (obj.data.delete_TtConcernMan.affected_rows >= 1) {
        this.isConcern = true;
        //获取用户的关注数
        let info = await QUERY(
          "post",
          "",
          "   PCUsers(where: {id: {_eq: " + this.user + "}}) { concernNum  }"
        );
        // 关注数+1
        info.data.PCUsers[0].concernNum -= 1;
        //修改用户的关注数
        let datas = await UPDATE(
          "POST",
          "",
          "  update_User(where: {id: {_eq: " +
            this.user +
            "}}, _set: {concernNum: " +
            info.data.PCUsers[0].concernNum +
            "}) {  affected_rows }"
        );
      } else {
        this.isConcern = false;
      }
      //alert("后台支持");
      // console.log("leave-message: cancle-concern")
      // console.log(obj)
    },
  },
};
</script>

<style>
.about-author {
  background: #fff;
  padding: 30px 35px 40px 35px;
  width: 780px;
  margin: 55px auto 25px auto;
}
.about-author .aboutAut-top {
  position: relative;
}
.clr {
  zoom: 1;
}
.about-author .aboutAut-top .avater {
  display: inline-block;
  width: 141px;
  height: 141px;
  border: 12px solid #f4f4f4;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
.about-author .aboutAut-top .avater {
  float: left;
}

a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
a:-webkit-any-link {
  cursor: pointer;
}
.about-author .attened {
  text-align: right;
}
.about-author .attened a {
  font-size: 15px;
  color: #999 !important;
}
.about-author .aboutAut-top .avater img {
  width: 125px;
  height: 125px;
  vertical-align: top;
  border: 8px solid #dedede;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
}
.about-author .aboutAut-top .msg {
  padding-left: 38px;
  overflow: hidden;
  padding-top: 35px;
}
.about-author .aboutAut-top .msg .name {
  font-size: 24px;
  color: #2f2f2f;
}
.about-author .aboutAut-top .msg .type {
  font-size: 15px;
  color: #2f2f2f;
  padding-left: 35px;
}
.about-author .aboutAut-top .msg .type em {
  display: inline-block;
  vertical-align: -7px;
  margin-left: 10px;
  background: url(../../../assets/icon/icon.png) no-repeat -324px -108px;
  width: 21px;
  height: 25px;
}
.about-author .aboutAut-top .nums {
  font-size: 0;
  padding: 8px 0 8px 0;
}
.about-author .aboutAut-top .nums span {
  font-size: 15px;
  color: #999;
  line-height: 30px;
}
.about-author .aboutAut-top .nums span.line {
  padding: 0 10px;
}
.about-author .aboutAut-top .saying {
  color: #999;
  line-height: 20px;
  font-size: 0;
}
.about-author .aboutAut-top .saying .lQuote {
  margin-right: 8px;
}
.about-author .aboutAut-top .saying span {
  font-size: 15px;
}
.about-author .aboutAut-top .saying .rQuote {
  background-position: -48px -569px;
  margin-left: 10px;
}
.about-author .aboutAut-top .saying em {
  display: inline-block;
  background: url(../../../assets/icon/icon.png);
  width: 13px;
  height: 14px;
}
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}
.about-author .sendMsg {
  padding-top: 40px;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.about-author .sendMsg textarea {
  display: block;
  width: 100%;
  height: 96px;
  padding: 12px 20px;
  line-height: 30px;
  font-size: 15px;
  color: #2f2f2f;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #c1c2c7;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  resize: none;
}
.about-author .sendMsg .sendBtn {
  margin-top: 30px;
  text-align: center;
}
.about-author .sendMsg .sendBtn a {
  display: inline-block;
  width: 156px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  background: #ea7754;
  color: #fff;
  font-size: 15px;
  border-radius: 23px;
  -webkit-border-radius: 23px;
}
.about-author .personMsg {
  font-size: 0;
  color: #999;
  line-height: 20px;
  text-align: center;
  padding-top: 50px;
}
.about-author .personMsg span {
  font-size: 15px;
  padding: 0 18px;
}
.about-author .personMsg span a {
  color: #999;
  font-size: 15px;
}
.about-author .personMsg .qIcon em {
  background-position: -441px -106px;
  width: 28px;
  height: 23px;
}
.about-author .personMsg span em {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  background: url(../../../assets/icon/icon.png) no-repeat;
}
.about-author .personMsg .wxIcon em {
  background-position: -531px -110px;
  width: 27px;
  height: 22px;
}
.about-author .personMsg .eIcon em {
  background-position: -486px -110px;
  width: 27px;
  height: 18px;
}
.VVVV {
  color: #fda444;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 125px;
  left: 110px;
  display: inline-block;
  width: 20px;
  height: 20px;
  /* opacity: 0.85; */
  background-size: 100% 100%;
  background-image: url("~@/assets/icon/v.png");
}
</style>
