<template>
  <div>
    <!-- 留言 -->
    <div>
      <V-LeaveMessage
        :author="author"
        @sendMessage="sendMessage"
        :authorId="authorId"
      ></V-LeaveMessage>
    </div>
    <!-- 他的文章,评论他的,他的评论 -->
    <div class="author-history">
      <div class="switch-control">
        <ul class="clr">
          <li @click="goToArticle()" :class="{ active: switchItem == 1 }">
            TA的文章<span>({{ articleNum }})</span>
          </li>
          <li @click="goToBeCommented()" :class="{ active: switchItem == 2 }">
            评论TA的<span>({{ beCommentedNum }})</span>
          </li>
          <li @click="goToCommentTo()" :class="{ active: switchItem == 3 }">
            TA的评论<span>({{ commentToNum }})</span>
          </li>
          <li
            @click="goToCommentmessage()"
            :class="{ active: switchItem == 4 }"
          >
            TA的留言板<span>({{ commentmessage }})</span>
          </li>
        </ul>
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import LeaveMessage from "@/components/author/leave-message/index.vue";
import { INSERT, QUERY, DELETE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      authorId: 1,
      articleNum: 0,
      beCommentedNum: 0,
      commentToNum: 0,
      commentmessage: 0, //留言数
      switchItem: 1,
      author: {
        name: "",
        praiseNum: 0,
        articleNum: 0,
        intro: "",
        xlName: "",
        wxName: "",
        email: "",
        img: "",
        authorId: -1,
        industry: "",
        level: 0,
        celebrity: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      //监听路由是否变化
      if (to.path != from.path) {
        if (to.path === "/articleed") {
          this.switchItem = 1;
          document.body.scrollTop = document.documentElement.scrollTop = top;
        }
        if (to.path === "/beCommented") {
          this.switchItem = 2;
          document.body.scrollTop = document.documentElement.scrollTop = top;
          return;
        }
        if (to.path === "/commentTo") {
          this.switchItem = 3;
          document.body.scrollTop = document.documentElement.scrollTop = top;
          return;
        }
        if (to.path === "/commentTo") {
          this.switchItem = 4;
          document.body.scrollTop = document.documentElement.scrollTop = top;
          return;
        }
      }
    },
  },
  created() {
    this.init();
    this.goToArticle();
  },
  components: {
    "V-LeaveMessage": LeaveMessage,
  },
  methods: {
    async init() {
      this.authorId = Number(this.$route.query.id);
      this.getAuthorById();
      // this.$router.push({path:'/author/article', query: {
      //         id: this.authorId
      // }});

      ///显示文章数
      let info = await QUERYED(
        "post",
        "",
        "  Article_aggregate ( where: {userid: {_eq: " +
          this.authorId +
          "}}){    aggregate {      count    }  }"
      );
      this.articleNum = info.data.Article_aggregate.aggregate.count;
      //显示被评论数
      let data = await QUERYED(
        "post",
        "",
        "BeComment_aggregate(where: {author: {_eq: " +
          this.authorId +
          "}}) {    aggregate {      count    }  }"
      );
      this.beCommentedNum = data.data.BeComment_aggregate.aggregate.count;
      ///显示评论数
      let datas = await QUERYED(
        "post",
        "",
        "  TTSeeComment_aggregate(where: {commentator: {_eq: " +
          this.authorId +
          "}}) {    aggregate {      count    }  }"
      );
      this.commentToNum = datas.data.TTSeeComment_aggregate.aggregate.count;
      //显示留言数
      let dataed = await QUERYED(
        "post",
        "",
        "  TTPretiveMessageUser_aggregate(where: {beuser: {_eq: " +
          this.authorId +
          "}}) { aggregate { count     }  }"
      );
      this.commentmessage =
        dataed.data.TTPretiveMessageUser_aggregate.aggregate.count;
    },

    ///显示用户信息
    async getAuthorById() {
      let info = await QUERYED(
        "post",
        "",
        "PCUsers(where: {id: {_eq: " +
          this.authorId +
          "}})  {    articleNum   celebrity  concernNum   praiseNum  commentToNum  id    img    intro    level industry  beCommentedNum  name xlName  wxName email  }"
      );
      this.author.authorId = info.data.PCUsers[0].id;
      this.author.name = info.data.PCUsers[0].name;
      // this.author.praiseNum=info.data.PCUsers[0].praiseNum;
      this.author.articleNum = info.data.PCUsers[0].articleNum;
      this.author.intro = info.data.PCUsers[0].intro;
      this.author.xlName = info.data.PCUsers[0].xlName;
      this.author.wxName = info.data.PCUsers[0].wxName;
      this.author.level = info.data.PCUsers[0].level;
      this.author.celebrity = info.data.PCUsers[0].celebrity;
      this.author.email = info.data.PCUsers[0].email;
      this.author.img = this.api.LoginURL.concat(info.data.PCUsers[0].img);
      this.author.industry = info.data.PCUsers[0].industry;
      // this.beCommentedNum=info.data.PCUsers[0].beCommentedNum;
      // this.articleNum=info.data.PCUsers[0].articleNum;
      // this.commentToNum=info.data.PCUsers[0].commentToNum;

      //显示作者一共获赞多少
      let dataed = await QUERYED(
        "post",
        "",
        "   Article_aggregate(where: {authorId: {_eq: " +
          this.authorId +
          "}}) {  aggregate {   sum {   praiseNum   }  } }"
      );
      if (dataed.data.Article_aggregate.aggregate.sum.praiseNum == null) {
        this.author.praiseNum = 0;
      } else {
        this.author.praiseNum =
          dataed.data.Article_aggregate.aggregate.sum.praiseNum;
      }
    },
    goToArticle() {
      this.switchItem = 1;
      this.$router.push({
        path: "/articleed",
        query: {
          id: this.authorId,
        },
      });
    },
    goToBeCommented() {
      this.switchItem = 2;
      this.$router.push({
        path: "/beCommented",
        query: {
          id: this.authorId,
        },
      });
    },
    goToCommentTo() {
      this.switchItem = 3;
      this.$router.push({
        path: "/commentTo",
        query: {
          id: this.authorId,
        },
      });
    },
    goToCommentmessage() {
      this.init();
      this.switchItem = 4;
      this.$router.push({
        path: "/message",
        query: {
          id: this.authorId,
        },
      });
    },
    /////提交留言
    async sendMessage(id, msg) {
      //   /获取登陆id
      this.user = this.$store.state.userinfo.id;
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
        });
      } else {
        if (msg == null || msg == "") {
          this.$message({
            message: "留言内容不能为空！",
            type: "warning",
          });
        } else {
          let info = await INSERT(
            "POST",
            "",
            "   insert_TtPretiveMessage(objects: {user: " +
              this.user +
              ', createTime: "' +
              new Date() +
              '", beuser: ' +
              id +
              ', Message: "' +
              msg +
              '"}) {   affected_rows }'
          );
          if (info.data.insert_TtPretiveMessage.affected_rows > 0) {
            this.$message({
              message: "留言成功！",
              type: "success",
            });
            this.init();
            this.goToArticle();
          } else {
            this.$message({
              message: "留言失败！",
              type: "warning",
            });
          }
        }
      }
    },
  },
};
</script>

<style>
.author-history {
  padding-top: 30px;
  width: 810px;
  margin: 0 auto;
  background: #fff;
  padding: 30px 20px;
}
.author-history .switch-control {
  position: relative;
}
.clr {
  zoom: 1;
}
.author-history .switch-control li.active {
  border-color: #abbabf;
}
.author-history .switch-control li {
  float: left;
  border: 2px solid transparent;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  color: #4a5b65;
  font-size: 16px;
  cursor: pointer;
}
li {
  list-style: none;
}
</style>
